






































import { mapState, mapGetters } from 'vuex';
import { DateTime } from 'luxon';

import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';

import { getCopyStakeReport, getCopyStakeReportPdf } from '@/api/CopyStake';

import router from '@/router';
import { doDownload } from '@/helpers/download';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};
const MAX_DATE = DateTime.now().minus({ month: 1 }).toFormat('yyyy-MM-dd');

export default {
  name: 'CopyStakeGameProvidersView',
  components: { VExtendedDataPicker },
  data(): any {
    return {
      MAX_DATE,
      isLoading: false,
      date: MAX_DATE,
      total: null,
      list: [],
      options: { ...defaultOptions }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('FeatureFlags', ['features', 'loading']),
    ...mapState('app', ['isSuperAdmin']),
    headers(): { [key: string]: string }[] {
      return [
        { text: 'Name', value: 'provider', width: '30%' },
        { text: 'GGR', value: 'ggr', width: '30%' },
        { text: 'Report', value: 'report', width: '30%' }
      ];
    },
    isEnabledCopyStake(): boolean {
      return (
        this.features.find((feature) => feature.name === 'live_streamer')
          ?.enabled === true
      );
    },
    formattedDate(): string {
      return DateTime.fromISO(this.date).toFormat('yyyy-MM-dd');
    }
  },
  watch: {
    operatorId(): void {
      this.getCopyStakeList();
    },
    loading: {
      handler(value: boolean): void {
        if (value || this.isEnabledCopyStake || this.isSuperAdmin) return;

        router.push('/');
      },
      immediate: true
    }
  },
  methods: {
    getCopyStakeList(): void {
      if (!this.operatorId) return;

      this.isLoading = true;

      getCopyStakeReport({
        date: this.formattedDate,
        size: this.options.itemsPerPage,
        page: this.options.page - 1
      })
        .then((response) => {
          this.list = response.content;
          this.total = response.totalElements;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getCopyStakeReportPdf(provider: string): void {
      getCopyStakeReportPdf({
        date: this.formattedDate,
        provider
      }).then((res) => {
        const fileName = `${provider.toLowerCase()}_${this.formattedDate}`;

        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/pdf'
          })
        );
        doDownload(url, `${fileName}.pdf`);
      });
    }
  }
};
